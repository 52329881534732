import React, {useEffect, useState, useCallback, useContext} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from "axios";
import useGetData from "Hooks/useGetData";
import { PageConstraint, PageHeader, TableContainer } from 'Components/layout'
import { Button, SearchInput } from "Components/input";
import { Modal, ToolPanel, DataTable, Column } from "Components/readonly";
import { globalSearch } from 'Utils/search'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'
import CreateQrModal from './components/CreateQrModal'
import ReadQrModal from './components/ReadQrModal'
import useModalController from 'Hooks/useModalController'
import { QrPageConstraint } from "./components/styles";
import { AuthContext } from 'Context'



const QrCodesPage = () => {
  const intl = useIntl()
  const { user } = useContext(AuthContext)
  const [isLoading, qrCodeList, setQrCodeList] = useGetData('/rapi/qrCodes', [], [])
  const [rows, setRows] = useState(localStorage.hasOwnProperty('numChemicalRows') ? parseInt(localStorage.getItem('numChemicalRows')) : 5)
  const [modalControllerEditQrCode] = useModalController({title: intl.formatMessage({id: 'qr-codes.title-single'})})
  const [modalControllerReadQrCode] = useModalController({title: intl.formatMessage({id: 'qr-codes.title-single'})})
  const [selectedData, setSelectedData] = useState({});
  const [hideProductModalController] = useModalController({title: intl.formatMessage({id: 'common.delete'})})
  const [inputFilterLoc, setInputFilterLoc] = useState('');
  const [inputFilterProd, setInputFilterProd] = useState('');
  const [filteredList, setFilteredList] = useState({});
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

  /**
   * Change the number of total table row that will be showed in table list
   * This will change on both table component views at the same time
   */
  useEffect(() => {
    localStorage.setItem("numChemicalRows", rows);
  }, [rows]);

  /**
   * Check if current user has access to add QR-code
   * @returns {*|boolean}
   */
  const hasAccess = function() {
    return (user.isSuperAdmin || user.actingAsSuperAdmin || user.user.roleValues.chemical_list !== "not_authorized");
  }

  /**
   * Will filter structure of the get data for table list
   * @param search
   * @param list
   * @returns {*[]}
   */
  const filterList = useCallback((list, search) => {
    let data = [];
    if (typeof list === "object") list.forEach((row, key) => {
      let products = [];
      row.pivot.forEach((obj) => products.push(obj?.sds?.[0]?.user_language_variant?.[0]?.name ?? "NaN"));
      data.push({
        increment: (key + 1),
        id: row.id,
        location_id: row?.location?.id,
        location_name: (row.location_name ?? intl.formatMessage({id: 'qr-codes.no-location'})),
        location: (row.location_name ?? ""),
        products: products,
        productSearch: products.join(","),
        productIds: row.products,
        created_at: row.created_at
      });
    });

    // Search filter the list
    if (typeof search === "string" && search.length > 0) {
      data = globalSearch([...data], search, ['location_name', 'productSearch']);
    }
    return data;
  }, [intl]);

  /**
   * Delete QR code from the active select choices
   * @returns {Promise<void>}
   */
  const deleteQrCode = async (row) => {
    try {
      const response = await axios.delete('/rapi/qrCodes/' + row.id);
      setQrCodeList(response.data);
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  /**
   * Create the product column
   * @param row
   * @returns {JSX.Element|string}
   */
  const productColumn = (row) => {
    if ((row.products.length > 0)) {
      return <>
        <div className="flex">
          <span className="truncate">{row.products.slice(0, 8).join(", ")}</span>
          {row.products.length > 1 && (
            <span>({row.products.length})</span>
          )}
        </div>
      </>
    }
    return intl.formatMessage({id: 'qr-codes.all-products'});
  }

  /**
   * Will open modal for edit the QR-Code (same as create but with active results)
   * Not used today, but you can use it by adding "onRowClick={editQrCode}" to
   * DataTable component
   * @param event
   */
  /*
  const editQrCode = (event) => {
    setSelectedData(event.data)
    modalControllerEditQrCode.open()
  }
   */

  /**
   * Will combine multiple buttons under the same table cell
   * @param row
   * @returns {JSX.Element}
   */
  const tableAside = (row) => (
    <>
      <div className="button-container">
        <VisitQrCodePageBtn row={row} />
        <ShowQrCodeModalBtn row={row} />
        {(hasAccess()) && (
          <DeleteQrCodeBtn row={row} />
        )}
      </div>
    </>
  );

  /**
   * Will create a button that will open the QR-code page
   * @param row
   * @returns {JSX.Element}
   */
  const VisitQrCodePageBtn = ({ row }) => {
    const qrUrl = (publicUrl.origin ?? publicUrl.href) + '#/user-area/qr/' + row.id;
    return <a href={qrUrl} target="_blank" rel="noopener noreferrer">
      <Button
        icon="pi pi-external-link"
        style={{margin: 0}}
        label={intl.formatMessage({id: 'common.visit'})}
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    </a>
  };

  /**
   * Will create an open edit modal button for table column
   * @param row
   * @returns {JSX.Element}
   */
  const ShowQrCodeModalBtn = ({ row }) => (
    <Button
      primary
      style={{margin: 0}}
      icon="pi pi-arrow-circle-right p-c p-button-icon-left"
      label={intl.formatMessage({id: 'common.show'})}
      onClick={(event) => {
        event.stopPropagation();
        setSelectedData(row)
        modalControllerReadQrCode.open()
      }}
    />
  );

  /**
   * Will create a delete button for table column
   * @param row
   * @returns {JSX.Element}
   */
  const DeleteQrCodeBtn = ({ row }) => (
    <Button
      primary
      icon="pi pi-trash"
      onClick={(event) => {
        event.stopPropagation();
        hideProductModalController.open({data: row});
      }}
    />
  );

  /**
   * The table component
   * @param listData
   * @param children
   * @returns {JSX.Element}
   */
  const GetTableComponent = ({listData, children}) => {
    const [first, setFirst] = useState(0)
    return (
      <QrPageConstraint>
        <TableContainer className="fix-ie-table-width flextable">
          {qrCodeList && (
            <DataTable
              responsive={true}
              className="clickable-rows"
              value={listData}
              loading={isLoading}
              rows={rows}
              paginator={true}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              first={first}
              onPage={(e) => {
                setFirst(e.first)
                setRows(e.rows)
              }}
            >
              {children}
              <Column style={{width: '10%'}} key="created_at" field="created_at"
                      header={intl.formatMessage({id: 'common.created'})} sortable={true}/>
              <Column
                style={{width: '20%', minWidth: '300px'}}
                className="buttons"
                key="qr_code"
                field="qr_code"
                body={tableAside}
                header={""}
                sortable={false}
              />
            </DataTable>
          )}
        </TableContainer>
      </QrPageConstraint>
    )
  };

  /**
   * Update list on changes
   */
  useEffect(() => {
    setFilteredList({
      location: filterList(qrCodeList.location, inputFilterLoc),
      products: filterList(qrCodeList.products, inputFilterProd)
    });
  }, [qrCodeList, inputFilterLoc, inputFilterProd, filterList]);

  return (
    <>
      <h1>
        <FormattedMessage id="qr-codes.title"/>
      </h1>
      <PageConstraint>
        <Modal
          modalController={modalControllerEditQrCode}
          modalData={selectedData}
          setQrCodeList={setQrCodeList}
          ContentComponent={CreateQrModal}
        />
        <Modal
          modalController={modalControllerReadQrCode}
          modalData={selectedData}
          ContentComponent={ReadQrModal}
        />
        <Modal
          modalController={hideProductModalController}
          ContentComponent={modalContentConfirm}
          contentTextId="qr-codes.confirm-delete"
          confirmTextId="qr-codes.confirm"
          onConfirm={deleteQrCode}
        />
        <PageHeader>
          <h1>{intl.formatMessage({id: 'common.location'})}</h1>
          <ToolPanel>
            <SearchInput value={inputFilterLoc} onChange={(event) => {
              setInputFilterLoc(event.target.value)
            }}/>
            {(hasAccess()) && (
              <Button
                primary
                icon="pi pi-plus p-c p-button-icon-left"
                label={intl.formatMessage({id: 'qr-codes.create-qr-code'})}
                onClick={() => {
                  // Pass in an isset location_id (e.g 0 is fine) to tell modal
                  // that I want to create QR from location
                  setSelectedData({location_id: 0});
                  modalControllerEditQrCode.open()
                }}
              />
            )}
          </ToolPanel>
        </PageHeader>
        <GetTableComponent listData={filteredList.location}>
          <Column style={{width: '58%'}}
                  key="location_name"
                  field="location_name"
                  header={intl.formatMessage({id: 'common.location'})}
                  sortable={true}
          />
        </GetTableComponent>
      </PageConstraint>
      <PageConstraint>
        <PageHeader>
          <h1>{intl.formatMessage({id: 'chemicals.product'})}</h1>
          <ToolPanel>
            <SearchInput value={inputFilterProd} onChange={(event) => {
              setInputFilterProd(event.target.value)
            }}/>
            {(hasAccess()) && (
              <Button
                primary
                icon="pi pi-plus p-c p-button-icon-left"
                label={intl.formatMessage({id: 'qr-codes.create-qr-code'})}
                onClick={() => {
                  // Pass in empty object to tell modal that I want to create QR from products
                  setSelectedData({})
                  modalControllerEditQrCode.open()
                }}
              />
            )}

          </ToolPanel>
        </PageHeader>
        <GetTableComponent listData={filteredList.products}>
          <Column
            style={{width: '60%'}}
            key="products"
            field="products"
            header={intl.formatMessage({id: 'chemicals.product'})} sortable={true}
            body={productColumn}
          />
        </GetTableComponent>
      </PageConstraint>
    </>
  );
};

export default QrCodesPage;
