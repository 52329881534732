import React, { useContext } from 'react'
import { AuthContext } from 'Context'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { Dropdown, TextInput } from 'Components/input'
import { FlexRowCenter, FlexRowResponsiveLg } from 'Components/layout'
import { Button, Panel } from 'Components/readonly'

const SlimPanel = styled(Panel)`
  padding: 0 10px 10px 10px;
`

const HygieneThresholdRow = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const HygieneThresholdComponentRowContainer = styled(FlexRowCenter)`
  margin-top: 10px;

  ${Button} {
    margin-bottom: 0;
  }
`

const HygieneThresholdComponentRow = styled(FlexRowResponsiveLg)`
  &&& {
    display: flex;
    flex: 1 1 auto;

    > * {
      margin-right: 10px;
      display: flex;
      flex: 1 1 5%;
      width: 12em;
      min-width: auto;

      &:nth-child(2) {
        flex: 2 1 10%;
      }
    }

    @media screen and (max-width: 1280px) {
      > * {
        width: 100%;
      }

      > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
`

const Heading = styled(FlexRowCenter)`
  &&& {
    h3 {
      padding: 0;
    }

    ${Button} {
      margin-bottom: 0;
    }
  }
`

const EditHygieneThreshold = ({ ht, ingredientId, message, update, remove }) => {
  const intl = useIntl()

  const typeOptions = [
    { value: 'level', label: intl.formatMessage({ id: 'hygiene-thresholds.level' }) },
    { value: 'short_term', label: intl.formatMessage({ id: 'hygiene-thresholds.short_term' }) }
  ]

  const exposurePathOptions = [
    { value: 'total_dust', label: intl.formatMessage({ id: 'hygiene-thresholds.total_dust' }) },
    { value: 'inhalable_fraction', label: intl.formatMessage({ id: 'hygiene-thresholds.inhalable_fraction' }) },
    { value: 'respirable_fraction', label: intl.formatMessage({ id: 'hygiene-thresholds.respirable_fraction' }) }
  ]

  const unitOptions = [
    { value: 'ppm', label: intl.formatMessage({ id: 'hygiene-thresholds.ppm' }) },
    { value: 'mg_m3', label: intl.formatMessage({ id: 'hygiene-thresholds.mg_m3' }) },
    { value: 'gu_m3', label: intl.formatMessage({ id: 'hygiene-thresholds.gu_m3' }) },
    { value: 'fibers_m3', label: intl.formatMessage({ id: 'hygiene-thresholds.fibers_m3' }) }
  ]

  return (
    <HygieneThresholdRow>
      <SlimPanel lighter>
        {message && (
          <p style={{padding: '10px 0 0 0' }}><em>{message}</em></p>
        )}
        <HygieneThresholdComponentRowContainer>
          <HygieneThresholdComponentRow>
            <Dropdown value={ht.type} onChange={(e) => update('type', e)} options={typeOptions}
                      appendTo={document.body}/>
            <Dropdown
              value={ht.exposure_path}
              onChange={(e) => update('exposure_path', e)}
              options={exposurePathOptions}
              appendTo={document.body}
            />
            <TextInput value={ht.value} onChange={(e) => update('value', e)}/>
            <Dropdown value={ht.unit} onChange={(e) => update('unit', e)} options={unitOptions}
                      appendTo={document.body}/>

          </HygieneThresholdComponentRow>
          <Button icon="pi pi-trash" onClick={remove}/>
        </HygieneThresholdComponentRowContainer>
      </SlimPanel>
    </HygieneThresholdRow>
  )
}

const EditIngredientHygieneThresholdList = ({ value, onChange, ingredientId }) => {
  const intl = useIntl()
  const { activeMarket } = useContext(AuthContext)

  const add = () => {
    const newRow = {}
    const newValue = [...value]
    newValue.push(newRow)
    onChange({ target: { value: newValue } })
  }

  const remove = (i) => {
    const newValue = [...value]
    newValue.splice(i, 1)
    onChange({ target: { value: newValue } })
  }

  const update = (i, prop, e) => {
    const newValue = e.target.value ? e.target.value : null
    const newItem = { ...value[i], [prop]: newValue, ['country_id']: activeMarket }
    const newList = [...value]
    newList.splice(i, 1, newItem)
    onChange({ target: { value: newList } })
  }

  return (
    <>
      <Heading>
        <h3>
          <FormattedMessage id="hygiene-thresholds.title"/> <FormattedMessage id={'lang.' + activeMarket}/>
        </h3>
        <Button icon="pi pi-plus-circle" onClick={add}/>
      </Heading>
      {value.map((ht, i) => {
        if(ht.cas_id !== "" || ht.ingredient_id === ingredientId) {
          return <EditHygieneThreshold
            key={i}
            ht={ht}
            message={(ht.ingredient_id === ingredientId ? intl.formatMessage({ id: 'hygiene-thresholds.ingredient-missing-cas-id' }) : "")}
            ingredientId={ingredientId}
            update={(prop, e) => update(i, prop, e)}
            remove={() => remove(i)}/>
        }
      })}
    </>
  )
}

export default EditIngredientHygieneThresholdList
