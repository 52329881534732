import React from 'react'
import axios from 'axios'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { Field, FieldGroup, Form } from 'Components/form'
import { TextInput } from 'Components/input'
import { Button, ModalActionsFooter } from 'Components/readonly'
import { GrowlContext } from 'Context'
import EditIngredientHygieneThresholdList from './editIngredientHygieneThresholdList'

const Wrapper = styled.div`
  ${FieldGroup} {
    padding: 0 20px;
  }
`

const EditIngredientDialog = ({ modalController, onSuccess }) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = React.useState(false)
  const { displayError, displaySuccess } = React.useContext(GrowlContext)

  const ingredient = modalController.data.ingredient

  const submit = async (formData) => {

    try {
      setIsLoading(true)
      await axios.put('/rapi/ingredients/' + ingredient.id, formData)
      displaySuccess(intl.formatMessage({ id: 'admin.ingredients.ingredient_updated' }))
      onSuccess({ ...ingredient, ...formData })
      modalController.close()
    } catch (e) {
      displayError(e)
    }
    setIsLoading(false)
  }

  const validateHygieneThresholds = (value) => {
    const isValid = (ht) => {
      const errors = []
      if (!ht.unit) {
        errors.push({ id: 'validation.required', values: { attribute: 'hygiene-thresholds.unit' } })
      }
      if (!ht.value || String(ht.value).trim().length === 0) {
        errors.push({ id: 'validation.required', values: { attribute: 'hygiene-thresholds.value' } })
      }
      return errors
    }
    return value.map(isValid).flat(2)
  }

  return (
    <Wrapper>
      <Form onSubmit={submit}>
        <FieldGroup>
          <Field
            id="names_sv"
            Component={TextInput}
            label={intl.formatMessage({ id: 'admin.ingredients.productname_sv' })}
            initialValue={ingredient.names_sv}
          />
          <Field
            id="names_en"
            Component={TextInput}
            label={intl.formatMessage({ id: 'admin.ingredients.productname_en' })}
            initialValue={ingredient.names_en}
          />
          <Field
            id="names_no"
            Component={TextInput}
            label={intl.formatMessage({ id: 'admin.ingredients.productname_no' })}
            initialValue={ingredient.names_no}
          />
          <Field
            id="names_dk"
            Component={TextInput}
            label={intl.formatMessage({ id: 'admin.ingredients.productname_dk' })}
            initialValue={ingredient.names_dk}
          />
        </FieldGroup>
        <FieldGroup columns="1">
          <Field
            id="cas_id"
            Component={TextInput}
            label={intl.formatMessage({ id: 'chemicals.form.ingredient.cas_id' })}
            initialValue={ingredient.cas_id}
          />
          <Field
            id="eg_id"
            Component={TextInput}
            label={intl.formatMessage({ id: 'chemicals.form.ingredient.eg_id' })}
            initialValue={ingredient.eg_id}
          />
          <Field
            id="index_id"
            Component={TextInput}
            label={intl.formatMessage({ id: 'chemicals.form.ingredient.index_id' })}
            initialValue={ingredient.index_id}
          />
          <Field
            id="reach_id"
            Component={TextInput}
            label={intl.formatMessage({ id: 'chemicals.form.ingredient.reach_id' })}
            initialValue={ingredient.reach_id}
          />
        </FieldGroup>
        <FieldGroup>
          <Field
            id="hygiene_thresholds"
            Component={EditIngredientHygieneThresholdList}
            initialValue={ingredient.hygiene_thresholds}
            ingredientId={ingredient.id}
            validation={(value) => validateHygieneThresholds(value)}
          />
        </FieldGroup>
        <ModalActionsFooter>
          <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={modalController.close}/>
          <Button submit primary label={intl.formatMessage({ id: 'common.save' })} isLoading={isLoading}/>
        </ModalActionsFooter>
      </Form>
    </Wrapper>
  )
}

export default EditIngredientDialog
