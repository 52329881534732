import { Menu as PrimeMenu } from 'primereact/menu'
import styled from 'styled-components'
import colors from '../colors.scss'

export const Menu = styled(PrimeMenu)`
  &&& {
    &.p-menu {
      border: none;
      border-radius: 0;
      width: auto;
      background: none;
      font-family: 'Exo 2';
      font-size: 18px;
      max-width: 320px;
    }

    .p-submenu-header {
      font-size: 18px;
      background-color: #1b1a37;
    }

    .p-menuitem {
      padding-bottom: 0;
    }

    & .p-menuitem-text {
      color: ${colors.white};
    }

    .mark .p-menuitem-text::after {
      content: ' ';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${colors.red};
      display: inline-block;
      margin-left: 10px;
    }

    .sub {
      .p-menuitem-icon {
        padding-left: 20px;
      }

      .p-menuitem-text {
        font-size: 16px;
        color: ${colors.white};
      }
    }

    & .p-menuitem-link {
      padding: 15px 30px 20px 15px;
      border-left: 5px solid transparent;
      display: flex;

      &:focus {
        box-shadow: none;
        background-color: #1b1a37;
      }

      .p-menuitem-icon {
        font-size: 1.25em;
      }

      .p-menuitem-icon.fa-qrcode, .p-menuitem-icon.fa-cubes {
        width: 24px;
        font-size: 1em;
      }
      .p-menuitem-icon.fa-qrcode {
        font-size: 0.9em;
      }
    }

    & .p-menuitem-link:hover,
    .active .p-menuitem-link {
      background-color: ${colors.darkBackground};
      border-left: 5px solid ${colors.accent};

      & .p-menuitem-text {
        color: ${colors.white};
      }
    }

    & .p-menuitem-link:hover {
      background-color: #1b1a37;
    }

    @media (min-width: 992px) and (max-width: 1279px) {
      .p-menuitem-text {
        display: none;
      }

      .p-menuitem-icon,
      .sub .p-menuitem-icon {
        padding-left: 0;
        margin-right: 0;
        font-size: 30px;
      }
    }
  }
`
